<style lang="scss" scoped>
.page-assetuser-stock {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
.assets {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-assetuser-stock">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部属事业部" size="medium" filterable
                        clearable v-model="listQuery.companyId" @change="changeCompany">
                        <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部使用者" size="medium" filterable
                        clearable v-model="listQuery.ownerId" @change="_search">
                        <el-option v-for="ws in allAssetUser" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <!--<el-select class="c-el-input mrgr5 mrgb5" placeholder="请输入资产查询" size="medium" clearable remote
                        filterable v-model="listQuery.assetId" :remote-method="remoteMethod" :reserve-keyword="true" @change="_search">
                        <el-option-group>
                            <div style="padding: 5px 20px;">
                                <div class="col9">图片/资产名称 (条形码)</div>
                            </div>
                            <el-option v-for="(o, i) in assets" :key="i" :value="o.id" :label="o.name">
                                <div class="col9 assets">
                                    <span class="mrgr5">
                                        <el-image class="asset-image" :src="uploadBaseUrl + o.imageUrl">
                                            <div slot="error">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </span>
                                    <span>
                                        {{ o.name }} ({{ o.barCode }})
                                    </span>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>-->
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="medium">
                <el-table-column label="图片【分类-条形码】资产名称" min-width="280">
                        <template slot-scope="scope" v-if="scope.row.asset">
                            <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                                :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                                :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                                <div slot="error">
                                    <i class="el-icon-picture-outline error-image"></i>
                                </div>
                            </el-image>
                            <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                            <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
                        </template>
                    </el-table-column>
                <el-table-column label="库存数量" prop="stock"></el-table-column>
                <el-table-column label="所属事业部" prop="company.name" min-width="95"></el-table-column>
                <el-table-column label="使用者">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ownerType === valOwnerAssetUser()">{{ tranceOwnerName(scope.row) }}</span>
                        </template>
                    </el-table-column>
                <el-table-column label="创建时间" prop="creationTime" :formatter="tableDateFormat" min-width="100"></el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "pageAssetUserStock",
    mixins: [enumConfigs],
    data() {
        return {
            pageHeader: {
                desc: "使用者库存即为在当前使用者手中的资产数量，单次只能查询一个使用者的库存数据。"
            },
            tableData: [],
            total: null,
            listQuery: {
                ownerType: null,
                ownerId: "",
                companyId: "",
                assetId: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            allCompany: [],
            allAssetUser: [],
            assets: []
        }
    },
    watch: {
        "$store.getters.enumConfigs": {
            handler() {
                this.initData()
            },
            deep: true,
            immediate: false
        }
    },
    mounted() {
        this.setSysPageHeader(this.pageHeader)
        if (this.$store.getters.enumConfigs && this.$store.getters.enumConfigs.AssetOwnerType) {
            this.initData()
        }
    },
    methods: {
        async initData() {
            this.listQuery.ownerType = this.valOwnerAssetUser()
            await this.getAllCompany()
            await this.getAllAssetUser()
            this.getDataList()
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funStock.getStockList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async getAllCompany(){
            await funInfomation.searchCompany().then(res => {
                this.allCompany = res.items
                if (res.items && res.items.length === 1) {
                    this.listQuery.companyId = res.items[0].id
                }
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser({ companyId: this.listQuery.companyId }).then(res => {
                this.allAssetUser = res.items
                if (res.items && res.items.length === 1) {
                    this.listQuery.ownerId = res.items[0].id
                }
            })
        },
        // 事业部改变
        async changeCompany() {
            this.listQuery.ownerId = ""
            await this.getAllAssetUser()
            this._search()
        },
        // 所属使用者
        tranceOwnerName(row) {
            let temp = {}
            if (row.ownerType === this.valOwnerAssetUser()) {
                temp = this.allAssetUser.find(x => {
                    return row.ownerId === x.id
                })
            }
            return temp && temp.name || ""
        },
        // 资产查询
        remoteMethod(v) {
            let params = {
                categoryId: null,
                keywords: v,
                takeNumber: 20
            }
            funInfomation.searchAsset(params).then(res => {
                this.assets = res.items
            })
        },
        _search() { 
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>